<template>
<v-app id="inspire">
    <div class="px-4">
        <v-container class="container--fluid grid-list-md text-center">
            <h1>Store Event Logs</h1>
        </v-container>
        <v-card elevation="2" outlined shaped tile class="pa-1">
            <div class="d-flex flex-no-wrap justify-space-between" v-if="hasPermission('ROLE_VIEW_STORE_EVENT_LOG')">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout row wrap>
                            <v-flex xs12 sm6>
                                <v-text-field label="storeNo" v-model="query.storeNo" single-line outlined @change="search($event, 'storeNo')" data-cy="module-store-number"></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-text-field label="storeName" v-model="query.storeName" single-line outlined @change="search($event, 'storeName')" data-cy="module-store-name"></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-text-field label="remarks" v-model="query.remarks" single-line outlined @change="search($event, 'remarks')" data-cy="module-remarks"></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6>
                                
                            </v-flex>
                            <v-col cols="12" md="5">
                                <v-menu v-model="fromDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-from">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field label="From" prepend-icon="mdi-calendar" readonly :value="fromDateDisp" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="en-in" v-model="query.dateFrom" no-title @input="fromDateMenu = false" :min="minDate" :max="fromMax">
                                        <v-btn text color="primary" @click="clearFromDate()" data-cy="button-clear">Clear
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-menu ref="menu" v-model="toDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-to"> 
                                    <template v-slot:activator="{ on }">
                                        <v-text-field label="To" prepend-icon="mdi-calendar" readonly :value="toDateDisp" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="en-in" v-model="query.dateTo" no-title @input="toDateMenu = false" :max="maxDate" :min="toMin">
                                        <v-btn text color="primary" @click="clearToDate()">Clear
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout wrap>
                            <v-flex xs12 sm6 v-if="hasPermission('ROLE_VIEW_STORE_EVENT_LOG')">
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color=success @click="queryData(true)" data-cy="button-search">
                                    <v-icon dark left> mdi-magnify</v-icon> Search
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
        </v-card>

        <v-data-table :hide-default-header="true" :headers="headers" :items="displayEventLogs" :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalEventLogs" :footer-props="{'items-per-page-options': [10]}" class="elevation-2 mt-4 px-4">
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr :colspan="headers.length">
                        <th v-for="header in headers" :key="header.text">
                            <span class="d-flex justify-center">{{header.text}}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                        <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.storeNo }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.storeName }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.dateCreated }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.totalTransactionAmount }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.remarks }} </span></td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Found no results.
                </v-alert>
            </template>
        </v-data-table>
    </div>
</v-app>
</template>

<script>
import API from '@/api/API.js'
import moment from 'moment';
import {
    mapGetters
} from 'vuex';

export default {
    name: 'Transactions',
    data() {
        return {
            headers: [],
            displayEventLogs: [],
            remainderEventLogs: [],
            loading: true,
            selectedUser: {},
            page: 1,
            totalEventLogs: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 10
            },
            query: {
                storeNo: '',
                storeName: '',
                remarks: '',
                dateFrom: '',
                dateTo: '',
            },
            fromDateMenu: false,
            fromDateVal: "",
            toDateMenu: false,
            toDateVal: "",
            minDate: "1997-01-01",
            maxDate: "",
            fromMax: "",
            toMin: ""
        }
    },
    watch: {
        options: {
            handler() {
                console.log('1')
                this.queryData()
            },
        },
        deep: true,
    },
    computed: {
        //get role attributes
        ...mapGetters([
            'attributes',
            'user',
            'authorities'
        ]),
        fromDateDisp() {
            this.toMin = moment().format(this.query.dateFrom)
            return this.query.dateFrom;
        },
        toDateDisp() {
            this.fromMax = moment().format(this.query.dateTo)
            return this.query.dateTo;
        }
    },
    async mounted() {
        this.setHeaders()
        this.setDefaultDateRange()
        //this.queryData()
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                const limit = itemsPerPage
                const eventLogListResponse = await API.getStoreEventLogRecon(pageNumber, limit, this.query)
                console.log(JSON.stringify(eventLogListResponse))
                if (!eventLogListResponse || eventLogListResponse.error) {
                    //error getting data
                    console.log(`${eventLogListResponse.error}`)
                } else {
                    var filteredList = eventLogListResponse.storeEventLogs
                    if (page > 1 && this.remainderEventLogs.length > 0) {
                        filteredList = this.remainderEventLogs.concat(filteredList)
                    }
                    this.totalEventLogs = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayEventLogs = filteredList.slice(0, end)
                    this.remainderEventLogs = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = this.totalEventLogs / itemsPerPage
                    const hasMoreResponse = await API.getStoreEventLogRecon(this.totalEventLogs, 1, this.query)
                    if (!hasMoreResponse || hasMoreResponse.error) {
                        //error getting data
                        console.log(`${hasMoreResponse.error}`)
                    } else {
                        var hasMoreList = hasMoreResponse.storeEventLogs
                        if(hasMoreList.length > 0) {
                          this.totalEventLogs = this.totalEventLogs + 1
                          this.numberOfPages = this.numberOfPages + 1
                        }
                    }
                }
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        },
        setHeaders() {
            var headers = [{
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'Store No',
                    value: 'storeNo'
                },
                {
                    text: 'Store Name',
                    value: 'storeName'
                },
                {
                    text: 'Date Created',
                    value: 'dateCreated'
                },
                {
                    text: 'Total Amount',
                    value: 'totalTransactionAmount'
                },
                {
                    text: 'Remarks',
                    value: 'remarks'
                }
            ]
            this.headers = headers
        },
        search(event, type) {
            this.query[type] = event
        },
        getToday() {
            const today = moment().format("yyyy-MM-DD")
            return today
        },
        setDefaultDateRange() {
            this.query.dateFrom = this.getToday();
            this.query.dateTo = ''
            this.maxDate = moment().add(1, "days").format("yyyy-MM-DD")
            this.fromMax = moment().add(1, "days").format("yyyy-MM-DD")
            this.toMin = moment().format(this.query.dateFrom)
        },
        clearToDate() {
            this.query.dateTo = ''
            this.toDateMenu = false
        },
        clearFromDate() {
            this.query.dateFrom = ''
            this.fromDateMenu = false
        }
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%
}
</style>
